<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>How do I apply for an Aviation Reference Number (ARN)?</h2>
      <p>
        An Aviation Reference Number, or ARN, is simply a number that is provided to you
        by CASA. It is used for interacting with CASA systems and processes; for example,
        when applying for <a href="/how-to-apply-for-medical">an aviation medical</a>. To
        apply for an ARN, you will need several identity documents and
        <a href="https://www.casa.gov.au/standard-page/individual-aviation-reference-number">
        apply online using the CASA website.
        </a>
      </p>
      <p>
        There is no cost involved in applying for an ARN and they are perpetual. It is
        worth getting this administrative task out of the way early on in your flight
        training.
      </p>
    </div>
  </div>
</template>

<script>
import StudentFAQ from '@/components/StudentFAQ.vue'

export default {
  components: {
    StudentFAQ
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
</style>
